<template>
    <div>
        <div class="top">
            <img class="left" :src="detail.picUrl" alt="" />
            <div class="right">
                <div>
                    <div class="title">{{ detail.libName }}</div>
                    <div class="desc">{{ detail.address }}</div>
                    <div class="item">
                        <img src="@/assets/images/Frame-44.png" alt="" />
                        <span>开馆时间：工作日{{ detail.workStartTime }}-{{ detail.workEndTime }}; 周末{{ detail.sundayStartTime }}-{{ detail.sundayEndTime }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-45.png" alt="" />
                        <span>服务电话：{{ detail.phone }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-46.png" alt="" />
                        <span>电子邮箱：{{ detail.email }}</span>
                    </div>
                </div>
                <div class="butt" v-if="detail.libType != 4" @click="detail.officialWebsite ? window.open(detail.officialWebsite) : $message.warning('该场馆暂无官网')">前往官网</div>
            </div>
        </div>
        <div class="titles">场馆介绍</div>
        <div class="details">{{ detail.description }}</div>
		<template v-if="detail.watchImg">
			<el-divider content-position="left">值班表</el-divider>
			<div>
				<el-image 
				    style="width: 400px; height: auto;"
				    :src="detail.watchImg" 
				    :preview-src-list="[detail.watchImg]">
				  </el-image>
			</div>
		</template>
    </div>
</template>

<script>
import { getLibraryDetails } from '@/api';
export default {
    name: 'detail',
    data() {
        return {
            detail: {},
        };
    },
    created() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            getLibraryDetails(this.$route.query.id).then((res) => {
                this.detail = res.data;
                this.$emit('tabList', res.data.libType);
            });
        },
        //  传入标准格式日期返回小时和分钟
        //  @param {String} date 标准格式日期
        //  @return {String} 时分
        getHourMinute(date) {
            if (!date) {
                return '';
            }
            const d = new Date(date);
            const hour = d.getHours();
            const minute = d.getMinutes();
            return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
        },
    },
};
</script>

<style scoped lang="less">
.top {
    display: flex;

    .left {
        width: 580px;
        height: 385px;
        border-radius: 10px;
    }

    .right {
        flex: 1;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-weight: 700;
            font-size: 20px;
            color: #333333;
            margin-bottom: 8px;
        }

        .desc {
            margin: 2px 0 24px 0;
            font-size: 13px;
            line-height: 19px;
            color: #999999;
        }

        .item {
            display: flex;
            align-items: center;
            margin-top: 16px;

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }

            span {
                font-size: 16px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }

        .butt {
            padding: 12px 110px;
            background: #eda200;
            box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
            border-radius: 4px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin-top: 37px;
            width: max-content;
        }
    }
}

.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    margin: 24px 0 12px 0;
}
.details {
    font-size: 16px;
    line-height: 23px;
    color: #666666;
}
.details:last-child {
    margin-bottom: 150px;
}
</style>
