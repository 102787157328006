<template>
    <div>
        <div class="filter">
            <div class="item">
                <div class="title">预约类型：</div>
                <el-select @change="(page = 1), getLibraryList" v-model="status" placeholder="请选择">
                    <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="search">
                <el-input prefix-icon="el-icon-search" placeholder="请输入搜索内容" v-model="search" clearable></el-input>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/venueReservationDetail', query: { id: item.id } })">
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.libName }}</div>
                    <div class="address">
                        <span>使用面积：{{ item.usableArea || 0 }}平</span>
                    </div>
                    <div class="address">
                        <span>可容纳人数：{{ item.capacityNum || 0 }}人</span>
                    </div>
                    <div class="address">
                        <span>所属场馆：{{ item.parentName }}</span>
                    </div>
                    <div class="butt">去预约</div>
                </div>
            </div>
        </div>
        <!-- <el-pagination @size-change="getLibraryList" @current-change="getLibraryList" :current-page.sync="page" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination> -->
    </div>
</template>

<script>
import { getLibraryList } from '@/api';
export default {
    name: 'Collection',
    data() {
        return {
            limit: 12,
            page: 1,
            total: 0,
            status: null,
            statusList: [
                { label: '全部', value: null },
                { label: '参观游玩', value: '0' },
                { label: '团体使用', value: '1' },
            ],
            list: [],
            search: null,
        };
    },
    watch: {
        search() {
            this.page = 1;
            this.getLibraryList();
        },
    },
    created() {
        this.getLibraryList();
    },
    methods: {
        goDetail() {
            this.$router.push({ path: '/VenueDetail' });
        },
        getLibraryList() {
            let data = {
                label: this.search,
                name: this.search,
                pageNum: this.page,
                pageSize: this.limit,
                id: this.$route.query.id,
            };
            this.$hao.getLibraryList(data).then((res) => {
                // this.list = res.rows;
                // this.total = res.total;
				
				this.list = res.data;
            });
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .is-active {
    background: white !important;
}

::v-deep .el-date-editor--daterange.el-input__inner {
    width: max-content;
}

.filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
        display: flex;
        align-items: center;

        .title {
            display: flex;
            width: max-content;
        }

        .el-select {
            width: 160px;
            margin-right: 24px;

            .el-input__inner {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }

    .search {
        ::v-deep .el-input {
            width: 240px;
            height: 30px;
            border-radius: 50px;
        }

        ::v-deep .el-input__inner {
            border-radius: 20px;
            height: 30px;
        }

        ::v-deep .el-input__icon {
            line-height: 30px;
            color: #eda200;
        }
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;
        position: relative;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 46px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }

            .butt {
                width: 100%;
                height: 44px;
                border-radius: 4px;
                color: #ffffff;
                font-size: 16px;
                line-height: 44px;
                text-align: center;
                margin-top: 24px;
                background: #eda200;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 10px;

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
        }
    }
}
</style>
