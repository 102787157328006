<template>
    <div>
        <div class="tab">
            <myBreadcrumb :nav="nav"></myBreadcrumb>
            <div class="tabs">
                <span v-for="(item, index) in filterList" :key="index" :class="{ active: tabs === index }" @click="tabsChange(index)">{{ item }}</span>
            </div>
        </div>
        <detail @tabList="tabList" v-if="tabs === 0"></detail>
        <!-- <Vr v-if="tabs === 1"></Vr> -->
        <Venue v-if="tabs === 2"></Venue>
        <Activity v-if="tabs === 3"></Activity>
    </div>
</template>

<script>
import detail from './components/detail';
import Venue from './components/Venue';
import Activity from './components/Activity';
// import Vr from '../vr/index';

export default {
    name: 'OrderDetail',
    components: {
        detail,
        Venue,
        Activity,
        // Vr
    },
    data() {
        return {
            filterList: [],
            tabs: 0,
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '总分馆',
                    to: { path: '/inCharge' },
                },
                {
                    title: '文化馆导览',
                },
            ],
        };
    },
    created() {
        // this.getLibraryDetails();
    },
    methods: {
        tabsChange(index) {
            this.tabs = index;
            if(this.tabs == 1) {
                this.$router.push({ path: '/vrList', query: { type: '1' }});
            } else if (this.tabs == 3) {
                this.$router.push({ path: '/excitingActivities', query: { id: this.$route.query.id } });
            } else {
                this.nav[2].title = this.filterList[index];
            }
            // if (this.tabs == 3) {
            //     this.$router.push({ path: '/excitingActivities', query: { id: this.$route.query.id } });
            // } else {
            //     this.nav[2].title = this.filterList[index];
            // }
        },
        tabList(type) {
            console.log(type);
            if (type == 4) {
                this.filterList = ['文化馆导览', 'VR展示', '场馆预约', '精彩活动'];
            } else {
                this.filterList = ['文化馆导览'];
            }
        },
    },
};
</script>

<style scoped lang="less">
.tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tabs {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    cursor: pointer;

    span {
        padding: 6.5px 10px;
        font-size: 14px;
        line-height: 17px;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #fff;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: #dcdfe6;
    }
    .active {
        color: #fff;
        background: #eda200;
        border-color: #eda200;
    }
}
</style>
